import { analyticsFactory } from 'common/utils/analytics'

const storefrontAnalytics = analyticsFactory('storefront')

// listening to storefront `/join` links clicks
window.addEventListener('DOMContentLoaded', () => {
  document.body.querySelectorAll('[href="/join"]').forEach(link => {
    link.addEventListener('click', e => {
      const block_element = e.target.closest('header') || e.target.closest('footer')
      const block_name = block_element?.nodeName?.toLowerCase()
        || e.target.closest('div[data-block-type]')?.getAttribute('data-block-type')
        || e.target.closest('.page > section')?.classList[0]
        || e.target.closest('.page > div')?.classList[0]
      const payload = {
        eventName: 'clicked_join_button',
        eventCategory: 'checkout',
        value: null,
        details: { block_element: block_name, theme: window.themeTitle }
      }

      storefrontAnalytics.track(payload)
    })
  })
})
